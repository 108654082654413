import UserPS1 from "../components/UserPS1";
import { text } from "../components/Syntax";

export const welcome = [
  text("Hi! {👋}[::wobble ::delay-1s ::repeat-2]", 500),
  text("My name is **Paul White**."),
  text(
    "I'm a **full-stack Javascript developer** and **User Interface Designer**."
  ),
  text(
    "I design and build **apps**, **websites** and **tools** that ~solve problems~ and ~create value~ for their users."
  ),
  text("> Learn more about me? (Y/n):"),
];
export const restart = [
  text("**Welcome back!**", 1000),
  text("> Select a directory:"),
];

export const exit = [
  text("Exiting... have a nice day! {👋}[@wobble @delay-1s @repeat-2]", 1000),
  text("_EXIT CODE *0*_"),
];

export const navigation = [
  text(`_run *start*_`),
  text(`_starting [paulwhite.codes](${window.location.href})_`),
  text(`_resolving dependencies..._`, 300),
  text(`:—coffee: ....`),
  text(`:—headphones: ....`),
  text(`:—imposter syndrome: ....`, 300),
  text(
    `_completed in *2.${Math.floor(Math.random() * (99 - 10 + 1) + 10)}s*_`,
    300
  ),
  text(`> Select a directory:`),
];

export const contact = [
  text("`_cd ./contact && run_`"),
  text("***{{address-card}} CONTACT***"),
  text(
    "`For more information or a project quote, please do not hesitate to reach out to me.`"
  ),
  text("`~*I look forward to being in touch with you!*~`"),
  text(""),
  text("`{{user-astronaut}} Paul White`"),
  text("`{{city}} New York, New York`"),
  text("`{{paper-plane}} [paul@develephant.co](mailto:paul@develephant.co)`"),
  text("`{b{linkedin}} [linkedIn](https://linkedIn.com/in/pauljakobwhite)`"),
];

export const about = [
  text("`_cd ./about && run_`"),
  text("***{{book-user}} ABOUT***"),
  text("> Select an option:"),
  text(""),
  text("`_–_ [[1]Who I Am?](who) <<< _// What I do_`"),

  text("`_–_ [[2]Why work with me?](why) <<< _// My values_`"),

  text("`_–_ [[3]What's my tech stack?](what) <<< _// My skills_`"),
];

export const who = [
  text("`**{{user-astronaut}} WHO I AM**`"),
  text(
    "`I'm a full-stack Javascript developer and UI designer based in 🗽New York, NY.`"
  ),
  text(
    "`During the day I'm the lead front-end developer for a local organization. In the evenings I spend my time working on freelance client projects and building my own products.`"
  ),
  text(
    "`My latest project is [[h]Human](projects), an iOS/Android app built using React Native.`"
  ),
  text(`> More about me:`),
  text("`_–_ [[2]Why work with me?](why)`"),
  text("`_–_ [[3]What's my tech stack?](what)`"),
];

export const why = [
  text("`**{{rocket-launch}} WHY WORK WITH ME**`"),
  text(
    "`<<< There’s nothing I enjoy more than designing and developing useful tools for good people. It's really that simple.`"
  ),
  text(
    "`<<< I place a high priority on continued learning and constantly challenge my own skill set with new projects to stay in tune with new and value-adding developments in my field.`"
  ),
  text(
    "`<<< Throughout the course of my career I have proven to be a capable and dependable problem-solver skilled at prioritizing and managing/collaborating on complex projects with proficiency.`"
  ),
  // text(
  //   "`In my current and past roles, I have consistently produced functional and user focused apps and tools—spanning multiple developer/designer disciplines—which have strategically resolved pain points and added value to my employers' and clients' businesses and, ABOVE ALL, their communities! Given the opportunity, I am confident I can do the same you.`"
  // ),
  text(`> More about me:`),
  text("`_–_ [[1]Who I am?](who)`"),
  text("`_–_ [[3]What's my tech stack?](what)`"),
];

export const what = [
  text("`**{{laptop-code}} MY TECH STACK**`"),
  text(""),
  text("||$$HTML$$ |:0:0:0:0:0:0:0:0:0:0| ||"),
  text("||$$CSS$$ |:0:0:0:0:0:0:0:0:0:0| ||"),
  text("||$$Javascript$$ |:0:0:0:0:0:0:0:0:x:x| ||"),
  text("||$$React$$ |:0:0:0:0:0:0:x:x:x:x| ||"),
  text("||$$React Native$$ |:0:0:0:0:0:0:x:x:x:x| ||"),
  text("||$$Vue$$ |:0:0:0:0:0:0:0:x:x:x| ||"),
  text("||$$Node$$ |:0:0:0:0:0:x:x:x:x:x| ||"),
  text("||$$Redux/Vuex$$ |:0:0:0:0:0:0:0:x:x:x| ||"),
  text("||$$Nuxt/Next$$ |:0:0:0:0:0:0:0:0:x:x| ||"),
  text("||$$Express$$ |:0:0:0:0:0:x:x:x:x:x| ||"),
  text("||$$Figma/Sketch$$ |:0:0:0:0:0:0:0:0:0:0| ||"),
  text(""),
  text(`> More about me:`),
  text("`_–_ [[1]Who I am?](who)`"),
  text("`_–_ [[2]Why work with me?](why)`"),
];

export const projects = [
  text("`_cd ./projects && run_`"),
  text("***{{window}} PROJECTS***"),
  text(`> Select a Project:`),
  text("`_–_ [[1]Human](human) <<< _//iOS & Android App_`"),
  text("`_–_ [[2]Forward](forward) <<< _//Multi-platform App_`"),
  text("`_–_ [[3]CoralFix](coralfix) <<< _//Windows App_`"),
  text("`_–_ [[4]Kiosk Manager](kiosk) <<< _//Web App_`"),
  text("`_–_ [[5]Document Manager](document) <<< _//Web App_`"),
  text("`_–_ [[6]This Site](pwc) <<< _//Web App_`"),
  // text("`_–_ [[7]Websites](websites) _//Client Websites_`"),
];

export const pwc = [
  text("`![paulwhite.codes](./projects/pwc.gif)`", 500),
  text("`***{{terminal}} paulwhite.codes***`"),
  text("`<<< @:Web: #*React*`"),
  // text("``"),
  text(
    "`<<< Rather than creating another prototypical portfolio site, I decided to do something fun that would act as a small window into the developer workflow I love. Have fun and be well!`"
  ),
  text("`<<< Built using ~React~.`"),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const human = [
  text("`![Human](./projects/human.png)`", 500),
  text("`***{{mobile}} Human*** <<< @:iOS: @:Android: #*ReactNative*`"),

  text(
    "`My latest project, ~Human~ is an ~iOS/Android~ app built using ~React Native~. The app is currently in private beta and slated to release in late 2022.`"
  ),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const forward = [
  text(
    "%./projects/forward.png,./projects/forward2.png,./projects/forward3.png%"
  ),
  text("`***{{window}} Forward*** Budget App`"),
  text("`<<< @:macOS: @:Windows: @:Web: #*Electron* #*Vue*`"),
  text(
    "`Forward is a budgeting macOS and web app built using ~VueJS~ and ~Electron~.`"
  ),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const coralfix = [
  text(
    "%./projects/coralfix.png,./projects/coralfix3.png,./projects/coralfix2.png%"
  ),
  text("`***{{window}} CoralFix***`"),
  text("`<<< @:macOS: @:Windows: #*Electron* #*Vue*`"),
  text(
    "`~CoralFix~ is a ~macOS~/~Windows~ app that manages server nodes/backups for high frequency trading programs. Built using ~Electron~ and ~VueJS~.`"
  ),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const kiosk = [
  text("%./projects/kiosk.png,./projects/kiosk3.png,./projects/kiosk2.png%"),
  text("`***{{browser}} Kiosk Manager***`"),
  text("`<<< @:macOS: @:Windows: @:Web: #*Electron* #*Vue*`"),
  text(
    "`~Kiosk Manager~ is a ~macOS~/~Windows/Web~ app for managing and scheduling content, and displaying that content on multi-building kiosks. Built using ~Electron~ and ~VueJS~.`"
  ),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const document = [
  text(
    "%./projects/document.png,./projects/document3.png,./projects/document2.png%"
  ),
  text("`***{{browser}} Document Manager***`"),
  text("`<<< @:macOS: @:Windows: @:Web: #*Electron* #*Vue*`"),
  text(
    "`~Document Manager~ is a ~macOS~/~Windows/Web~ app for managing documents with version control flows similar to Git. Built using ~Electron~ and ~VueJS~.`"
  ),
  text(
    "`[[p]{s{arrow-alt-to-left}} All Projects](projects) [[n]Next {s{arrow-alt-to-right}}](next)`"
  ),
];

export const websites = [];

export const back = [text("going back...")];

export const tldr = [
  text("`_cd ./tldr && run_`"),
  text("`***{{fast-forward}} TL;DR***`"),
  text("`>>> ~*Sometimes we just need the bullet points! {s{smile-wink}}*~`"),
  text(
    "`**{{user-astronaut}} About me** _- I'm a full-stack developer and UX/UI designer. I'm savvy with the following tech:`",
    0,
    "group-below"
  ),
  text(
    "`((HTML))((CSS))((Javascript))((React))((React Native))((Vue))((Node))((Redux/Vuex))((Nuxt/Next))((Express))((Firebase/GCP))((Sketch/Figma))`",
    0,
    "group-above"
  ),
  text(
    "`**{{laptop-code}} Services** - My services include: Web/Mobile/Desktop Apps, Logo/Brand Design, Interface Design and Websites (static or CMS).`"
  ),
  text(
    "`**{{paper-plane}} Contact** - For more information or a project quote, please do not hesitate to reach out to me.`"
  ),
  text("[paul@develephant.co](mailto:paul@develephant.co)"),
];
