export const defaultConfig = {
  typeSpeed: 5,
  hideNavigation: false,
  largeNavigation: false,
  autoFocus: true,
};

export const welcome = {
  ...defaultConfig,
  typeSpeed: 2,
  hideNavigation: true,
};

export const exit = {
  ...defaultConfig,
  hideNavigation: true,
};

export const navigation = {
  ...defaultConfig,
  largeNavigation: true,
};

export const contact = {
  ...defaultConfig,
};

export const about = {
  ...defaultConfig,
};

export const restart = { ...defaultConfig };

export const who = { ...defaultConfig };
export const why = { ...defaultConfig };
export const what = { ...defaultConfig };
export const projects = { ...defaultConfig };

export const pwc = { ...defaultConfig };
export const human = { ...defaultConfig };
export const forward = { ...defaultConfig };
export const coralfix = { ...defaultConfig };
export const kiosk = { ...defaultConfig };

export const document = { ...defaultConfig };
export const websites = { ...defaultConfig };
export const back = { ...defaultConfig };

export const tldr = { ...defaultConfig };
