import React from "react";
import { mainActions } from "../data/executables";

export default function Navigation({
  show,
  config,
  activeProgram,
  activeProgramName,
  onClickButton,
}) {
  if (!show) return null;
  return (
    <span className="line">
      <nav className={`navigation ${config.largeNavigation ? "" : "small"}`}>
        {mainActions.map((action, idx) => (
          <button
            className={`terminal-link terminal-action-button ${
              activeProgramName === action.runs ? "active" : ""
            }`}
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              onClickButton(action.accepts);
            }}
          >
            <span
              className={`terminal-link-key ${
                activeProgramName === action.runs ? "active" : ""
              }`}
            >
              {action.accepts}
            </span>
            {action.title}
          </button>
        ))}
      </nav>
    </span>
  );
}
