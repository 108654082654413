import { Fragment, useState } from "react";

import UserPS1 from "./UserPS1";

export default function CommandPrompt({
  show = false,
  description = "",
  userIP,
  onPress,
  onEnterKey,
  autoFocus,
  buttons,
  index,
}) {
  const [value, setValue] = useState("");

  const placeholder = () => {
    if (description) return description;

    const pl = buttons
      .filter((b) => !b.hidden)
      .map(({ accepts, title }) => accepts)
      .join(" / ");
    if (!pl.length) return 'Enter option key ( e.g. "a" for about )';
    else return pl;
  };

  if (!show) return null;
  return (
    <Fragment>
      <div className="command-prompt">
        <label className="line" htmlFor={`prompt${index}`}>
          <UserPS1 ip={userIP} />
        </label>
        <div className="input-container">
          <div className="user">
            <span className="caret">❯</span>
          </div>
          <div className="option-buttons">
            {buttons
              .filter((a) => !a.hidden)
              .map((action) => (
                <button
                  key={action.accepts}
                  className="option"
                  onClick={() => onPress(action.accepts)}
                >
                  {action.title}
                </button>
              ))}
          </div>

          <input
            id={`prompt${index}`}
            type="text"
            value={value}
            placeholder={placeholder()}
            onChange={(e) => {
              setValue(e.target.value.toLocaleLowerCase());
            }}
            autoFocus={autoFocus}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onEnterKey(value.toLocaleLowerCase());
                setValue("");
              }
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}
