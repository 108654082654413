import React, { useEffect, useRef } from "react";

export default function Terminal({ children, style, disableAutoScroll }) {
  const terminalRef = useRef();
  const terminalAnchorRef = useRef();

  const resizeObserver = new ResizeObserver(() => {
    // const viewportHeight = window.innerHeight;
    // const terminalHeight = terminalRef.current.clientHeight;
    // const terminalOverflows = terminalHeight > viewportHeight;
    if (terminalAnchorRef && terminalAnchorRef.current)
      terminalAnchorRef.current.scrollIntoView({ behavior: "smooth" });
  });
  const handleObserver = (ref, observe) => {
    if (ref && ref.current && observe) {
      resizeObserver.observe(ref.current);
      return;
    }
    if (ref && ref.current && !observe) {
      resizeObserver.disconnect();
    }
  };

  useEffect(() => {
    handleObserver(terminalRef, true);

    return () => handleObserver(terminalRef, false);
  }, [terminalRef]);

  return (
    <section className="terminal" {...style} ref={terminalRef}>
      {children}

      <div className="terminalAnchor" ref={terminalAnchorRef}></div>
    </section>
  );
}
