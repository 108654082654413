export const caret = "`<span class='caret'>❯</span>`";

export const dialogue = (arr) => {
  return [arr.join("\n")];
};

export const text = (string, ms, lineClass) => {
  const { innerWidth } = window;
  const chars = [...string];
  const length = chars.length || 0;
  const wrapText = innerWidth <= 600;
  const mobileViewport = innerWidth <= 600;
  let loaderLength = mobileViewport ? 37 - length : 40 - length;
  if (loaderLength <= 0) loaderLength = 1;
  const error = /!(.*?)!/gim;
  const title = /\*\*\*(.*?)\*\*\*/gim;
  const variable = /\*(.*?)\*/gim;
  const italic = /~(.*?)~/gim;
  // const terminal-link-key = /\[(.*?)\]/gim;
  const slider = /%(.*?)%/gim;
  const emphasis = /\*\*(.*?)\*\*/gim;
  const script = /_(.*?)_/gim;
  const scaleWrapper = /\|\|(.*?)\|\|/gim;
  const scale = /\|(.*?)\|/gim;
  const scaleTitle = /\$\$(.*?)\$\$/gim;
  const scale0 = /:0/g;
  const scaleX = /:x/g;
  const terminalActionLink = /\[\[(.*?)\](.*?)\]\((.*?)\)/gim;
  const terminalLink = /\[(.*?)\]\((.*?)\)/gim;
  const animation = /\{(.*?)\}\[(.*?)\]/gim;
  const icon = /\{\{(.*?)\}\}/gim;
  const tag = /\(\((.*?)\)\)/gim;
  const brandIcon = /\{b\{(.*?)\}\}/gim;
  const standardIcon = /\{s\{(.*?)\}\}/gim;
  const animationClasses = /::(\w*[A-Za-z_]+\w*)/gim;
  const smallTerminalNavigation = /\{\{(.*?)\}\}/gim;
  const terminalNavigation = /\{(.*?)\}/gim;
  const dependancy = /:(.*?):/gim;
  const ellipsis = /\.\.\./gim;
  const loader = /\.\.\.\./gim;
  const image = /!\[(.*?)\]\((.*?)\)/gim;
  const blockquote = />>> (.*$)/gim;
  const small = /<<< (.*$)/gim;
  const question = /^> (.*$)/gim;
  const programBreak = /---/g;

  const htmlString = string

    // @scripts
    .replace(script, `<span class="script">$1</span>`)
    // @blockquote
    .replace(
      blockquote,
      '<blockquote class="blockquote"><span>$1</span></blockquote>'
    )
    // @tag
    .replace(tag, '<span class="tag">$1</span>')
    // @standardIcon
    .replace(standardIcon, '<i class="fas fa-$1"></i>')
    // @brandIcon
    .replace(brandIcon, '<i class="fab fa-$1"></i>')
    // @icon
    .replace(icon, '<i class="fad fa-$1"></i>')
    // @scalewrap
    .replace(scaleWrapper, '<span class="scale-wrapper">$1</span>')
    // @scalewrap
    .replace(scale, '<span class="scale">$1</span>')
    // @scaleTitle
    .replace(scaleTitle, '<span class="scale-title">$1</span>')
    // @scale0
    .replace(scale0, '<span class="scale-on"></span>')
    // @scalex
    .replace(scaleX, '<span class="scale-off"></span>')
    // @question
    .replace(
      question,
      '`<span class="question"><span class="question-icon">?</span> $1</span>`'
    )
    // @small
    .replace(small, '<span class="small">$1</span>')
    // @slider
    .replace(slider, "slider: $1")
    // @image
    .replace(image, "<img class='terminal-image' alt='$1' src='$2' />")
    // @animation
    .replace(animationClasses, "animate__$1")
    // @animation
    .replace(animation, '<span class="animated animate__animated $2">$1</span>')
    // @dependancy
    .replace(dependancy, "<span class='dependancy'>$1</span>")
    // @loader
    .replace(
      loader,
      `<span class="loader">${"•".repeat(
        loaderLength
      )} <span class="checkmark">✅</span></span>`
    )
    // @ellipsis
    .replace(ellipsis, `<span class="loader">...</span>`)

    // @title
    .replace(title, '<span class="title">$1</span>')
    // @italic
    .replace(italic, '<span class="italic"><em>$1</em></span>')
    // @emphasis
    .replace(emphasis, '<span class="emphasis">$1</span>')
    // @variable
    .replace(variable, '<span class="variable">$1</span>')
    // @terminalActionLink
    .replace(
      terminalActionLink,
      `<button class="terminal-link inline-terminal-action-button" onClick="$1"><span class="terminal-link-key">$1</span>$2</button>`
    )
    // @terminalLink
    .replace(
      terminalLink,
      `<a class="terminal-link" href="$2" target="_blank">$1</a>`
    )
    // @terminalNavigationSmall
    .replace(
      smallTerminalNavigation,
      '<span class="navigation small">$1</span>'
    )
    // @terminalNavigation
    .replace(terminalNavigation, '<span class="navigation">$1</span>')

    // @error
    .replace(error, '<span class="error">$1</span>')
    // @break
    .replace(programBreak, '<span class="break"></span>');

  // .replace(/^### (.*$)/gim, "<h3>$1</h3>")
  // .replace(/^## (.*$)/gim, "<h2>$1</h2>")
  // .replace(/^# (.*$)/gim, "<span class='dependancy'>$1</span>")
  // .replace(/\n$/gim, "<br />");

  if (htmlString.search("slider") >= 0) {
    const sliderContent = htmlString
      .replace("slider: ", "")
      .split(",")
      .filter((x) => x !== ",");

    const slider = `<div id="${
      "id" + Math.floor(Math.random() * 1000000000)
    }" class="swiper-container"><div class="swiper-wrapper">${sliderContent
      .map(
        (image) =>
          `<div class='swiper-slide'><img src='${image}' alt="Project Preview"/></div>`
      )
      .join("")}</div></div></div>
    `;

    return slider;
  }

  if (htmlString.search("scale") >= 0) {
    return `\`${htmlString}\``;
  }
  return `<span class="line ${
    lineClass ? lineClass : ""
  }">${htmlString}</span>${ms ? `^${ms}` : ""}`;
};

// export const directoryList = (list) => {
//   const { innerWidth } = window;

//   const joiner = innerWidth > 600 ? "    " : " ";

//   return list
//     .map(
//       (item) =>
//         `\`<span class="line">${terminal-link(item[0], item[1])}</span>\``
//     )
//     .join(joiner);
// };
