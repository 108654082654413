import "@fortawesome/fontawesome-pro/js/all";

import * as configs from "../data/configs";
import * as exectables from "../data/executables";
import * as programs from "../data/programs";

import React, { useEffect, useState } from "react";

import Program from "../components/Program";
import Terminal from "../components/Terminal";
import View from "../components/View";

export default function Home() {
  const [userIP, setUserIP] = useState("0.0.0.0");
  const [programList, setProgramList] = useState(["welcome"]);
  const [disableAutoScroll, setDisableAutoScroll] = useState(true);

  useEffect(() => {
    if (programList.length >= 1) setDisableAutoScroll(false);
    if (programList[0] === "back" && programList.length >= 2) {
      setProgramList((prev) => [prev[2], ...prev]);
    }
  }, [programList]);

  useEffect(() => {
    fetch(
      "https://api.ipdata.co/?api-key=1de475c7819d19bbae5165ed78b04a8702e93a0263c9d8510a2ef5a5"
    )
      .then((response) => response.json())
      .then((response) => setUserIP(response.ip));
  }, []);

  return (
    <View>
      <Terminal disableAutoScroll={disableAutoScroll}>
        {[...programList].reverse().map((item, idx) => {
          return (
            <Program
              key={`${item}${idx}`}
              userIP={userIP}
              data={programs[item]}
              config={configs[item]}
              actions={exectables[item]}
              programName={item}
              activeProgram={idx === programList.length - 1}
              activeProgramName={programList[0]}
              index={idx}
              numberOfPrograms={programList.length}
              onUserInput={(input) => {
                setDisableAutoScroll(false);
                setProgramList((prev) => {
                  return [input, ...prev];
                });
              }}
              onCompletedDialogue={() => setDisableAutoScroll(true)}
            />
          );
        })}
      </Terminal>
    </View>
  );
}
