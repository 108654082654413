export const mainActions = [
  { title: "About me", accepts: "a", runs: "about", hidden: true },
  { title: "Projects", accepts: "p", runs: "projects", hidden: true },
  // { title: "Services", accepts: "s", runs: "services", hidden: true },
  { title: "Contact", accepts: "c", runs: "contact", hidden: true },
  { title: "TLDR", accepts: "t", runs: "tldr", hidden: true },
];

export const universalActions = [
  { title: "Exit", accepts: "e", runs: "exit", hidden: true },
  { title: "Back", accepts: "x", runs: "back", hidden: true },
  { title: "Human", accepts: "h", runs: "human", hidden: true },
  { title: "Forward", accepts: "f", runs: "forward", hidden: true },
  { title: "Coralfix", accepts: "o", runs: "coralfix", hidden: true },
  { title: "Kiosks", accepts: "k", runs: "kiosks", hidden: true },
  { title: "Documents", accepts: "d", runs: "document", hidden: true },
  { title: "Websites", accepts: "w", runs: "websites", hidden: true },
];

export const welcome = [
  { title: "Yes", accepts: "y", runs: "navigation" },
  { title: "No", accepts: "n", runs: "exit" },
  ...universalActions,
  ...mainActions,
];

export const restart = [...universalActions, ...mainActions];

export const exit = [
  { title: "Restart", accepts: "r", runs: "restart", hidden: false },
  ...universalActions,
  ...mainActions,
];

export const navigation = [...universalActions, ...mainActions];

export const contact = [...universalActions, ...mainActions];

export const about = [
  { title: "1", accepts: "1", runs: "who" },
  { title: "2", accepts: "2", runs: "why" },
  { title: "3", accepts: "3", runs: "what" },
  ...universalActions,
  ...mainActions,
];

export const who = [
  { title: "2", accepts: "2", runs: "why" },
  { title: "3", accepts: "3", runs: "what" },
  ...universalActions,
  ...mainActions,
];

export const why = [
  { title: "1", accepts: "1", runs: "who" },
  { title: "3", accepts: "3", runs: "what" },
  ...universalActions,
  ...mainActions,
];

export const what = [
  { title: "1", accepts: "1", runs: "who" },
  { title: "2", accepts: "2", runs: "why" },
  ...universalActions,
  ...mainActions,
];

export const projects = [
  { title: "1", accepts: "1", runs: "human" },
  { title: "2", accepts: "2", runs: "forward" },
  { title: "3", accepts: "3", runs: "coralfix" },
  { title: "4", accepts: "4", runs: "kiosk" },
  { title: "5", accepts: "5", runs: "document" },
  { title: "6", accepts: "6", runs: "pwc" },
  // { title: "7", accepts: "7", runs: "websites" },
  ...universalActions,
  ...mainActions,
];

export const human = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  { title: "Next", accepts: "n", runs: "forward", hidden: false },
];
export const forward = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  { title: "Next", accepts: "n", runs: "coralfix", hidden: false },
];
export const coralfix = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  { title: "Next", accepts: "n", runs: "kiosk", hidden: false },
];
export const kiosk = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  { title: "Next", accepts: "n", runs: "document", hidden: false },
];
export const document = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  { title: "Next", accepts: "n", runs: "pwc", hidden: false },
];
export const pwc = [
  ...universalActions,
  ...mainActions,
  { title: "All Projects", accepts: "p", runs: "projects", hidden: false },
  // { title: "Start Over", accepts: "n", runs: "human", hidden: false },
];
export const websites = [
  ...universalActions,
  ...mainActions,
  // { title: "All Projects", accepts: "p", runs: "???", hidden: false },
  // { title: "Next", accepts: "n", runs: "???", hidden: false },
];
export const back = [...universalActions, ...mainActions];

export const tldr = [...universalActions, ...mainActions];
