import "firebase/analytics";

import React, { Component } from "react";

import Swiper from "swiper";
import Typed from "react-typed";

// import "swiper/swiper-bundle.css";

export default class Prompt extends Component {
  // state = {
  //   dialogue: this.props.data.join("\n"),
  //   sliderPresent: false,
  //   sliderIdIndex: 0,
  //   sliderID: null,
  // };

  handleInlineLinks = () => {
    const links = document.getElementsByClassName(
      "inline-terminal-action-button"
    );
    if (links.length) {
      [...links].forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const target = e.currentTarget.getAttribute("onClick");
          this.props.onClickLink(target);
        });
      });
    }
  };

  swiperInstance = (sliderID) => {
    return new Promise((resolve) => {
      let el;
      try {
        el = document.querySelector(sliderID).swiper;
        resolve(el);
      } catch (error) {
        resolve(false);
      }
    });
  };

  handleSlider = async (sliderID) => {
    try {
      const swiper = new Swiper(sliderID, {
        slidesPerView: 1,
        loop: true,
        speed: 700,
        spaceBetween: 8,
        autoHeight: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    } catch (error) {
      console.log("slider error");
    }
    // const swiperInstance = await this.swiperInstance(sliderID);
    // if (swiperInstance) {
    //   setTimeout(() => {
    //     swiper.slideNext(2000);
    //     setTimeout(() => {
    //       swiper.slidePrev(15000);
    //     }, 200);
    //   }, 1000);
    // }
  };

  updateAndNotify = () => {
    this.typed.reset();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.updateAndNotify();
    }
  }

  // componentWillMount() {
  //   this.setState({ sliderPresent: this.state.dialogue.search("swiper") >= 0 });
  //   this.setState({ sliderIdIndex: this.state.dialogue.search("id") + 4 });
  //   this.setState({
  //     sliderID: `#${this.state.dialogue.substring(
  //       this.state.dialogue.search("id") + 4,
  //       this.state.dialogue.search("id") + 4 + 11
  //     )}`,
  //   });
  // }

  // componentDidMount() {
  //   if (this.state.sliderPresent) {
  //     const swiper = new Swiper(this.state.sliderID, {
  //       slidesPerView: 1,
  //       loop: true,
  //       speed: 700,
  //       spaceBetween: 200,
  //       autoHeight: true,
  //       autoplay: {
  //         delay: 500,
  //       },
  //     });
  //   }
  // }

  render() {
    const dialogue = this.props.data.join("\n");
    const sliderPresent = dialogue.search("swiper") >= 0;

    const sliderIdIndex = dialogue.search("id") + 4;
    const sliderID = `#${dialogue.substring(
      sliderIdIndex,
      sliderIdIndex + 11
    )}`;

    if (sliderPresent) {
      try {
        this.handleSlider(sliderID);
      } catch (error) {
        console.log("slider error");
      }
    }

    return (
      <Typed
        className="prompt"
        contentType="html"
        strings={[dialogue]}
        typeSpeed={this.props.speed}
        showCursor={false}
        onStringTyped={() => {
          this.props.onComplete();
          this.handleInlineLinks();
        }}
        typedRef={(typed) => {
          this.typed = typed;
        }}
        {...this.props}
        {...this.props.style}
      />
    );
  }
}
