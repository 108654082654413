import "./index.css";
import "firebase/analytics";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import app from "firebase/app";
import reportWebVitals from "./reportWebVitals";

app.initializeApp({
  apiKey: "AIzaSyCZR-wf95UvWOX2VZkP9-6oZ8ohHSJLD7w",
  authDomain: "paul-white-codes.firebaseapp.com",
  projectId: "paul-white-codes",
  storageBucket: "paul-white-codes.appspot.com",
  messagingSenderId: "1087497848446",
  appId: "1:1087497848446:web:0801fa9dcc2000dd52fa2f",
  measurementId: "G-S8E04Z26YS",
});

app.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
