import React from "react";

export default function UserPS1({ ip = "0.0.0.0" }) {
  return (
    <span className="user">
      <span className="hidden-sm">
        <span className="root">👤visitor</span>
        <span className="ref">@</span>
        <span className="host">{ip}</span>
      </span>
    </span>
  );
}
