import "./App.scss";

import { Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./views/Home";
import Test from "./views/Test";

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/test" component={Test} />
        <Route exact path="/" component={Home} />
      </div>
    </Router>
  );
}

export default App;
