import React, { Fragment, useState } from "react";

import CommandPrompt from "./CommandPrompt";
import Navigation from "./Navigation";
import Prompt from "./Prompt";
import app from "firebase/app";
import { text } from "./Syntax";

const analytics = app.analytics;

export default function Program({
  userIP,
  data,
  actions,
  config,
  onUserInput,
  description,
  index,
  numberOfPrograms,
  programName,
  activeProgram,
  activeProgramName,
  onCompletedDialogue,
}) {
  const [complete, setComplete] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const hasErrors = invalidInputs.length > 0;
  const errors = invalidInputs.map((x) => text(`!invalid input: ${x}!`));

  const onComplete = () => {
    setComplete(true);
    onCompletedDialogue();
  };

  const handleInput = (value) => {
    const possibleResponses = actions.map((a) => a.accepts);
    if (possibleResponses.includes(value)) {
      setConfirmed(true);
      const program = actions.find((a) => a.accepts === value).runs;
      onUserInput(program);
      analytics().logEvent("page_view", { page_title: program });
    } else {
      setInvalidInputs((prev) => [...prev, value]);
      setConfirmed(false);
    }
  };

  return (
    <Fragment>
      <article className={!activeProgram ? "terminated-program" : "program"}>
        <Prompt
          data={data}
          onComplete={onComplete}
          speed={config.typeSpeed}
          onClickLink={handleInput}
        />
        {hasErrors && <Prompt data={errors} speed={5} onComplete={() => {}} />}
        <div className="command-nav">
          <Navigation
            activeProgram={activeProgram}
            activeProgramName={activeProgramName}
            show={complete && !confirmed && !config.hideNavigation}
            config={config}
            onClickButton={handleInput}
          />
          <CommandPrompt
            userIP={userIP}
            show={complete && !confirmed}
            description={description}
            onPress={handleInput}
            onEnterKey={handleInput}
            buttons={actions}
            autoFocus={config.autoFocus}
            programName={programName}
            index={index}
          />
          {complete && !confirmed && index === 0 && (
            <p className="line italic script group-above">
              <span className="small">
                <em>
                  Type your selection above or use the blue buttons to navigate
                </em>
              </span>
            </p>
          )}
        </div>
      </article>
    </Fragment>
  );
}
